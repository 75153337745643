<template>
  <Loading v-if="isInitLoading" />
  <div class="redEnvelopes" v-else>
    <Header>我的卡券</Header>
    <div class="tab">
      <div
        class="tabItem"
        v-for="item in tabList"
        :key="item.id"
        :class="item.id === query.status ? 'active' : null"
        @click="changeTab(item)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="container" ref="scroll">
      <div class="list">
        <div
          class="listBg"
          v-for="item in list"
          :key="item.id"
          @click.stop="toggle(item)"
        >
          <div class="listItem">
            <div class="left">
              <img src="../../../assets/images/account/zqk.png" alt="" />
            </div>
            <div class="right">
              <div class="title">{{ item.ticket_name }}</div>
              <div class="subTitle">
                <span> {{ item.start_time }}至{{ item.end_time }} </span>
                <i v-if="!item.isShowGoodsName && item.goods_name"></i>
              </div>
            </div>
            <div class="img">
              <img
                v-if="item.status == 1"
                src="../../../../static/image/ysy.png"
                alt=""
              />
              <img
                v-else-if="item.is_overdue == 1"
                src="../../../../static/image/expired.png"
                alt=""
              />
            </div>
          </div>
          <div
            @click.stop="item.isShowGoodsName = false"
            class="usedList"
            v-if="item.isShowGoodsName"
          >
            <div class="usedListItem">使用商品：{{ item.goods_name }}</div>
            <i></i>
          </div>
        </div>
      </div>
      <div class="noData" v-if="!list.length">
        <img src="../../../../static/image/emptyBox.png" alt="" />
        <div>暂无卡券</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isInitLoading: true,
      tabList: [
        { id: 0, name: '全部' },
        { id: 1, name: '未使用' },
        { id: 2, name: '已使用' },
        { id: 3, name: '已过期' },
      ],
      query: {
        token: localStorage.getItem('token'),
        status: 0,
        pagesize: 20,
        pageindex: 1,
      },
      list: [],
      isNotMore: false,
    };
  },
  mounted() {
    this.getCouponList(1);
  },
  beforeDestroy() {
    if (this.$refs.scroll) {
      this.$refs.scroll.removeEventListener('scroll', this.onScroll);
    }
  },
  methods: {
    toggle(item) {
      if (item.goods_name) {
        item.isShowGoodsName = true;
      }
    },
    async getCouponList(isInit) {
      if (this.isNotMore) return;
      try {
        const res = await this.$api.coupon(this.query);
        if (res.code === 0) {
          if (res.data.length < this.query.pagesize) {
            this.isNotMore = true;
          }

          (res.data || []).forEach((e) => {
            e.isShowGoodsName = false;
          });

          if (this.query.pageindex == 1) {
            this.list = res.data;
          } else {
            this.list = this.list.concat(res.data);
          }
        } else {
          return this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isInitLoading = false;
        if (isInit) {
          this.$nextTick(() => {
            const scroll = this.$refs.scroll;
            scroll.addEventListener('scroll', this.onScroll, true);
          });
        }
      }
    },
    onScroll(e) {
      const clientHeight = e.target.clientHeight;
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (clientHeight + scrollTop >= scrollHeight) {
        console.log('到底了');
        this.query.pageindex++;
        this.getCouponList();
      }
    },
    changeTab(item) {
      this.query.status = item.id;
      this.query.pageindex = 1;
      this.isNotMore = false;
      this.getCouponList();
    },
  },
};
</script>

<style lang="less" scoped>
.redEnvelopes {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  .noData {
    text-align: center;
    img {
      width: 70px;
      margin-right: -10px;
    }
    div {
      font-size: 14px;
      color: #999;
    }
  }
  .tab {
    padding: 10px 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    .tabItem {
      font-size: 16px;
      flex: 1;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      &.active {
        span {
          color: #000000;
          position: relative;
          display: inline-block;
          padding: 0 3px;
          &::before {
            display: block;
            content: '';
            width: 100%;
            height: 2px;
            background: rgba(114, 118, 252, 0.6);
            position: absolute;
            left: 0;
            bottom: 4px;
          }
        }
      }
    }
  }
  .container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100px;
    background: #f8f8f8;
    padding: 20px;
    overflow: auto;
    .list {
      // padding: 15px;
      .listBg {
        background: #fff;
        margin-bottom: 20px;
      }
      .usedList {
        border-top: 1px dashed #ececec;
        padding: 4px 0 14px 65px;
        padding-left: 65px;
        position: relative;
        .usedListItem {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 10px;
        }
        i {
          display: block;
          width: 18px;
          height: 18px;
          margin-top: -2px;
          position: absolute;
          right: 10px;
          bottom: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          &::after {
            display: block;
            content: '';
            width: 7px;
            height: 7px;
            border-bottom: 1px solid #999;
            border-right: 1px solid #999;
            transform: rotate(-135deg);
          }
        }
      }
      .listItem {
        display: flex;
        padding: 15px 10px;
        align-items: center;
        position: relative;
        .img {
          position: absolute;
          right: 5px;
          top: 5px;
          img {
            width: 35px;
            height: 35px;
          }
        }
        .left {
          padding: 10px;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          background: #e0f8ff;
          img {
            width: 20px;
            vertical-align: middle;
          }
        }
        .right {
          flex: 1;
          min-width: 0;
          .title {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .subTitle {
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #cecece;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            i {
              display: block;
              width: 18px;
              height: 18px;
              margin-top: -2px;
              margin-left: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              &::after {
                display: block;
                content: '';
                width: 7px;
                height: 7px;
                border-bottom: 1px solid #999;
                border-right: 1px solid #999;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
}
</style>
